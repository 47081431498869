<template>
  <div>
    <div style="width: 100%; display: flex" v-if="!isAdd">
      <div class="storeList" style="width: 11%">
        <div class="title">
          <div class="span"></div>
          门店
        </div>
        <div
          class="store"
          v-for="(item, index) in storeList"
          v-bind:key="index"
          :class="hierarchy_id === item.hierarchy_id ? 'selectStore' : null"
          @click="handleStore(item)"
        >
          <div class="text">{{ item.hierarchy_name }}</div>
        </div>
      </div>
      <div style="width: 88%">
        <div class="tableTop">
          <div class="title" style="float: left">
            <div class="span"></div>
            会员等级
          </div>
          <div style="float: right; margin-right: 15px">
            <el-button type="primary" @click="handleAdd()"
              >新增会员等级</el-button
            >
          </div>
        </div>

        <div class="table">
          <el-table :data="tableData" stripe height="730">
            <el-table-column align="center" prop="level_Img" label="等级图标">
              <template slot-scope="scope">
                <img :src="scope.row.level_Img" class="levelImg" />
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="level_name"
              label="等级名称"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="sales_amount"
              label="累计消费金额"
            ></el-table-column>
            <!-- <el-table-column
              align="center"
              prop="add_user_realname"
              label="退货后是否影响会员等级"
            ></el-table-column> -->
            <el-table-column
              align="center"
              prop="discount_name"
              label="享受的折扣"
            >
              <template slot-scope="scope">
                <el-button type="text" @click="handleLook(scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
            <!-- <el-table-column
              align="center"
              prop="privilege_name"
              label="拥有的特权"
            ></el-table-column> -->
            <el-table-column
              align="center"
              prop="operation"
              label="操作"
              width="180"
            >
              <template slot-scope="scope">
                <div class="options">
                  <el-select
                    v-model="scope.row.select"
                    placeholder="请选择"
                    @change="handleDetails($event, scope.row)"
                  >
                    <el-option label="编辑" :value="1"></el-option>
                    <el-option label="删除" :value="2"></el-option>
                  </el-select>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagina">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            :page-sizes="[15, 30, 40, 50]"
            :page-size="15"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </div>
    <Add
      v-if="isAdd"
      @hide="getList"
      :type="type"
      :share_type="share_type"
      :hierarchy_id="hierarchy_id"
      :client_level_id="client_level_id"
    />
    <el-dialog
      :visible.sync="visiable"
      title="折扣信息"
      :destroy-on-close="true"
      :before-close="cancelDia"
    >
      <el-table :data="discountList" stripe>
        <el-table-column
          align="center"
          prop="discount_name"
          label="折扣名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="goods_type_txt"
          label="品类"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="discount"
          label="折扣"
        ></el-table-column>
        <!-- <el-table-column
          align="center"
          prop="date"
          label="有效时间"
          width="310"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.start_time + "-" + scope.row.end_time }}</div>
          </template>
        </el-table-column> -->
        <el-table-column align="center" prop="status" label="是否启用">
          <template slot-scope="scope">
            <div>{{ scope.row.status === 10 ? "禁用" : "启用" }}</div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="sort"
          label="优先级"
        ></el-table-column> </el-table
    ></el-dialog>
  </div>
</template>

<script>
import { userLevel, userLevelDel } from "@/api/member/integralSetting/index.js";
import { getFind } from "@/api/system/integralRule";
import { getMerchants } from "@/api/authority/menage";
import storage from "good-storage";

import Add from "./add";
export default {
  data() {
    return {
      storeList: [],
      hierarchy_id: 0,
      isAdd: false,
      tableData: [],
      type: "add",
      share_type: 0,
      client_level_id: 0,
      page: 1,
      limit: 15,
      total: 0,
      visiable: false,
      discountList: [],
    };
  },
  created() {
    const userInfo = storage.get("userInfo");
    this.userInfo = userInfo;
    this.getMerchantListReq();
    this.getRule();
  },
  components: {
    Add,
  },
  methods: {
    /*
     *  获取门店数据
     */
    getMerchantListReq() {
      getMerchants().then((res) => {
        if (res.code === 1) {
          this.storeList = res.data;
          this.hierarchy_id = res.data[0].hierarchy_id;
          this.getList();
        }
      });
    },
    cancelDia() {
      this.visiable = false;
      this.discountList = [];
    },
    handleLook(item) {
      this.visiable = true;
      this.discountList = item.discount;
    },
    /*
     *  积分规则
     */
    getRule() {
      getFind().then((res) => {
        if (res.code === 1) {
          if (res.data !== null) {
            this.share_type = res.data.share_type;
          }
        }
      });
    },
    /*
     *  获取会员等级列表数据
     */
    getList() {
      this.isAdd = false;
      const data = {
        hierarchy_id: this.hierarchy_id,
        page: this.page,
        limit: this.limit,
      };
      userLevel(data).then((res) => {
        if (res.code === 1) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    /*
     *  分页操作
     */
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    /*
     *  每页条数
     */
    handleSizeChange(val) {
      this.limit = val;
      this.getList();
    },
    /*
     *  选择门店
     */
    handleStore(item) {
      this.hierarchy_id = item.hierarchy_id;
      this.getList();
    },
    /*
     *  新增会员等级
     */
    handleAdd() {
      this.client_level_id = 0;
      this.type = "add";
      this.isAdd = true;
    },
    /*
     *  删除
     */
    handleDelete() {
      this.$confirm("此操作将删除该分类, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          userLevelDel({
            client_level_id: this.client_level_id,
          })
            .then((res) => {
              if (res.code === 1) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getList();
              }
            })
            .catch(() => {
              this.getList();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
          this.getList();
        });
    },
    /*
     *  操作
     */
    handleDetails(event, row) {
      this.client_level_id = row.client_level_id;
      if (event === 1) {
        this.type = "edit";
        this.isAdd = true;
      } else if (event === 2) {
        this.handleDelete();
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .title {
  position: relative;
  margin-bottom: 14px;
  padding-left: 5px;
  margin-left: 20px;
  line-height: 30px;
  display: flex;
  flex-direction: row;
  font-size: 16px;

  &::before {
    content: "";
    position: absolute;
    width: 4px;
    height: 15px;
    background: #2791ff;
    left: -8px;
    top: 8px;
    border-radius: 99px;
  }
}
.levelImg {
  width: 80px;
  height: 30px;

  img {
    width: 80px;
    height: 30px;
  }
}
.tableTop {
  height: 45px;
  background: linear-gradient(0deg, #f3f9ff, #ffffff);
  padding-top: 10px;
  border: 1px solid #ddd;
  border-radius: 6px 6px 0px 0px;
}

.storeList {
  padding-top: 15px;
  margin-right: 10px;
  background: #ffffff;
  height: 90vh;
  border: 1px solid #ddd;
  border-radius: 6px;

  .store {
    padding-left: 17px;
    width: 100%;
    background: white;
    padding: 10px 0;
    font-size: 14px;

    .text {
      margin-left: 15px;
    }
  }
  .store:hover {
    cursor: pointer;
  }

  .selectStore {
    background-color: #ecf5ff;
    .text {
      margin-left: 14px;
    }
  }
}
</style>