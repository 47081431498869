<template>
  <div>
    <div class="card">
      <el-page-header
        @back="goBack"
        :content="type === 'add' ? '新增会员等级' : '编辑会员等级'"
      ></el-page-header>
    </div>
    <div style="margin-top: 15px">
      <div class="info" style="margin-right: 10px">
        <div class="title">
          <div class="span"></div>
          会员等级基本信息
        </div>
        <div class="form">
          <el-form :model="info" :rules="rule" ref="info">
            <el-form-item
              label="等级名称"
              :label-width="formLabelWidth"
              prop="level_name"
            >
              <el-input
                v-model="info.level_name"
                autocomplete="off"
                style="width: 375px"
                placeholder="请输入等级名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="会员等级图片" :label-width="formLabelWidth">
              <div
                class="upload"
                @click="getLevelImg()"
                v-if="level_url === ''"
              >
                <div class="uploadLine"></div>
                <div class="uploadSu"></div>
              </div>
              <div v-if="level_url !== ''" @click="getLevelImg()">
                <img :src="level_url" class="imgBorder" />
              </div>
            </el-form-item>
            <el-form-item
              label="累计消费金额"
              :label-width="formLabelWidth"
              prop="sales_amount"
            >
              <el-input
                v-model="info.sales_amount"
                autocomplete="off"
                style="width: 375px"
                placeholder="请输入消费金额"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!-- <div class="info">
        <div class="title">
          <div class="span"></div>
          会员等级特权
        </div>
        <div style="display: flex">
          <div style="width: 300px; text-align: right">选择等级特权类型</div>
          <div class="privilege">
            <div
              class="box"
              v-for="(item, index) in discount"
              :key="index"
              :class="item.check === true ? 'select' : null"
              
            >
              <el-checkbox v-model="item.check" class="check"></el-checkbox>
              <div class="img">
                <img :src="item.check ? item.icon : item.noIcon" />
              </div>
              <div style="margin-top: 10px">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div style="margin-top: 10px">
      <div class="tableTop">
        <div class="title" style="float: left">
          <div class="span"></div>
          会员等级享受折扣
        </div>
        <div style="float: right; margin-right: 15px">
          <el-button type="primary" @click="handleAddDisCount()"
            >新增折扣</el-button
          >
        </div>
      </div>
      <div class="table">
        <el-table :data="discountList" stripe>
          <el-table-column
            align="center"
            prop="discount_name"
            label="折扣名称"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="goods_type_name"
            label="品类"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="discount"
            label="折扣"
          ></el-table-column>
          <!-- <el-table-column
          align="center"
          prop="date"
          label="有效时间"
          width="310"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.start_time + "-" + scope.row.end_time }}</div>
          </template>
        </el-table-column> -->
          <el-table-column align="center" prop="status" label="是否启用">
            <template slot-scope="scope">
              <div>{{ scope.row.status === 10 ? "禁用" : "启用" }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="sort"
            label="优先级"
          ></el-table-column>
          <el-table-column
            align="center"
            fixed="right"
            prop="operation"
            label="操作"
            width="180"
          >
            <template slot-scope="scope">
              <div class="options">
                <el-select
                  v-model="action"
                  placeholder="请选择"
                  @change="handleDetails($event, scope.row, scope.$index)"
                >
                  <el-option label="编辑" :value="1"></el-option>
                  <el-option label="删除" :value="2"></el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div style="text-align: center; margin-top: 15px">
      <el-button @click="goBack()">取消</el-button>
      <el-button type="primary" @click="submit('info')" :loading="loading"
        >保存</el-button
      >
    </div>
    <el-dialog
      :visible.sync="discountVisiable"
      :title="discountType === 'add' ? '新增会员享受折扣' : '编辑会员享受折扣'"
      :destroy-on-close="true"
      :before-close="cancelDisCount"
    >
      <el-form :model="discountForm" :rules="discountRule" ref="discountForm">
        <div class="form">
          <el-form-item
            label="折扣名称"
            :label-width="formLabelWidth"
            prop="discount_name"
          >
            <el-input
              v-model="discountForm.discount_name"
              autocomplete="off"
              style="width: 60%"
              placeholder="请输入折扣名称"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="选择品类"
            :label-width="formLabelWidth"
            prop="goods_type_id"
          >
            <el-select
              v-model="discountForm.goods_type_id"
              placeholder="请选择品类"
              style="width: 60%"
              multiple
              collapse-tags
            >
              <template v-for="item in attrList">
                <el-option
                  :label="item.goods_type_name"
                  :value="item.goods_type_id"
                  :key="item.goods_type_id"
                >
                  <span :style="{ marginLeft: item.span * 8 + 'px' }">{{
                    item.goods_type_name
                  }}</span>
                </el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item
            label="折扣"
            :label-width="formLabelWidth"
            prop="discount"
          >
            <el-input
              v-model="discountForm.discount"
              autocomplete="off"
              style="width: 60%"
              placeholder="请输入折扣值"
            ></el-input>
            折
          </el-form-item>
          <!-- <el-form-item
            label="有效期"
            :label-width="formLabelWidth"
            prop="date"
          >
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="discountForm.date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item> -->
          <el-form-item
            label="是否启用"
            :label-width="formLabelWidth"
            prop="status"
          >
            <el-radio-group v-model="discountForm.status">
              <el-radio :label="20">启用</el-radio>
              <el-radio :label="10">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="优先级"
            :label-width="formLabelWidth"
            prop="sort"
          >
            <div style="display: flex">
              <el-input
                v-enterNumber
                v-model="discountForm.sort"
                autocomplete="off"
                style="width: 60%"
                placeholder="请输入优先级"
              ></el-input>
              （数字大的优先）
            </div>
          </el-form-item>
          <div style="text-align: right">
            <el-button type="primary" @click="submitDisCount('discountForm')"
              >确定</el-button
            >
            <el-button @click="cancelDisCount">取消</el-button>
          </div>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="visiable"
      title="会员等级图片"
      :before-close="cancel"
    >
      <div>选择会员等级图片</div>
      <div class="levelList">
        <div
          class="level"
          :class="level_index === item.index ? 'selectLevel' : null"
          v-for="(item, index) in levelImgList"
          :key="index"
          @click="handleLevel(item)"
        >
          <div class="img">
            <img :src="item.icon" />
          </div>
        </div>
      </div>
      <div style="margin: 20px 0 15px 0">
        添加会员等级图片<span style="color: #fd563a">
          (注：建议上传100px * 100px的png图片)</span
        >
      </div>
      <el-upload
        class="avatar-uploader"
        action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
        :data="{
          directory: 'AGENT_INFO',
        }"
        :headers="{
          authorization: token,
        }"
        :show-file-list="false"
        :before-upload="beforeAvatarUpload"
        :on-success="handleImgSuccess"
      >
        <img v-if="level_img" :src="level_img" class="avatarOther" />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
      <div style="text-align: right">
        <el-button type="primary" @click="cancel">确定</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import birthday from "../../../../assets/images/member/icon_birthdays.png";
import anniver from "../../../../assets/images/member/icon_anniversarys.png";
import coupon from "../../../../assets/images/member/icon_couponss.png";
import clean from "../../../../assets/images/member/icon_cleanings.png";
import identifications from "../../../../assets/images/member/icon_identifications.png";
import redemptions from "../../../../assets/images/member/icon_redemptions.png";
import custody from "../../../../assets/images/member/icon_custodys.png";
import ship from "../../../../assets/images/member/icon_ships.png";

import birthdayGrey from "../../../../assets/images/member/icon_birthdayGrey.png";
import anniverGrey from "../../../../assets/images/member/icon_anniversaryGrey.png";
import couponGrey from "../../../../assets/images/member/icon_couponsGrey.png";
import cleanGrey from "../../../../assets/images/member/icon_cleaningGrey.png";
import identificationsGrey from "../../../../assets/images/member/icon_identificationGrey.png";
import redemptionsGrey from "../../../../assets/images/member/icon_redemptionGrey.png";
import custodyGrey from "../../../../assets/images/member/icon_custodyGrey.png";
import shipGrey from "../../../../assets/images/member/icon_shipGrey.png";

import Cookies from "js-cookie";
import storage from "good-storage";

import {
  privList,
  userLevelDetails,
  userLevelCreate,
  userLevel,
  userLevelUpdate,
  delDiscount,
} from "@/api/member/integralSetting/index";
import { getListReq } from "@/api/goods/goodsConfig/classify";

export default {
  data() {
    let validateNum = (rule, value, callback) => {
      if (!/^\d+$/.test(value)) {
        callback(new Error("请输入正整数"));
      } else {
        callback();
      }
    };
    return {
      info: {},
      formLabelWidth: "150px",
      checked: false,
      visiable: false,
      discountVisiable: false,
      discountType: "add",
      discountForm: {
        goods_type_id: [],
      },
      discountList: [],
      token: "",
      level_img: "",
      level_url: "",
      level_index: 0,
      attrList: [],
      loading: false,
      action: null,
      disIndex: null,
      rule: {
        level_name: [
          {
            required: true,
            message: "请输入等级名称",
            trigger: "blur",
          },
        ],
        sales_amount: [
          {
            required: true,
            message: "请输入累计消费金额",
            trigger: "blur",
          },
        ],
      },
      discountRule: {
        discount_name: [
          {
            required: true,
            message: "请填写折扣名称",
            trigger: "blur",
          },
        ],
        goods_type_id: [
          {
            required: true,
            message: "请选择品类",
            trigger: "change",
          },
        ],
        discount: [
          {
            required: true,
            message: "请填写折扣值",
            trigger: "blur",
          },
        ],
        sort: [
          {
            required: true,
            message: "请填写优先级",
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: "请选择是否启用",
            trigger: "change",
          },
        ],
        date: [
          {
            required: true,
            message: "请选择有效期",
            trigger: "change",
          },
        ],
      },
      levelImgList: [
        {
          index: 1,
          icon:
            "http://etouch-edianbao.oss-cn-shenzhen.aliyuncs.com/edianbao/agents/202011/28a704e001609bb3f5721b28b31fc0a918139fca.png",
        },
        {
          index: 2,
          icon:
            "http://etouch-edianbao.oss-cn-shenzhen.aliyuncs.com/edianbao/agents/202011/25ef5089505c02a1d206933ee489f88f01a1f8c0.png",
        },
        {
          index: 3,
          icon:
            "http://etouch-edianbao.oss-cn-shenzhen.aliyuncs.com/edianbao/agents/202011/6f495b515812bba2cc91118246614e9291707dba.png",
        },
        {
          index: 4,
          icon:
            "http://etouch-edianbao.oss-cn-shenzhen.aliyuncs.com/edianbao/agents/202011/9357e5cb49abfa07be7e55012c950ce2ed1fd604.png",
        },
        {
          index: 5,
          icon:
            "http://etouch-edianbao.oss-cn-shenzhen.aliyuncs.com/edianbao/agents/202011/b4566921d20757da16b6b5ea395166bdf97643c9.png",
        },
        {
          index: 6,
          icon:
            "http://etouch-edianbao.oss-cn-shenzhen.aliyuncs.com/edianbao/agents/202011/e9abca2e866c2d8068f364fecbb0e4caad9176f3.png",
        },
        {
          index: 7,
          icon:
            "http://etouch-edianbao.oss-cn-shenzhen.aliyuncs.com/edianbao/agents/202011/058b6b2e840dc34d97632349e6d000c83b70375e.png",
        },
        {
          index: 8,
          icon:
            "http://etouch-edianbao.oss-cn-shenzhen.aliyuncs.com/edianbao/agents/202011/5122d27f2828c21850fc3fb0cfd063cc8ac248a0.png",
        },
      ],
      discount: [
        {
          index: 1,
          name: "生日享有礼包",
          icon: birthday,
          noIcon: birthdayGrey,
          check: false,
        },
        {
          index: 2,
          name: "纪念日享有礼包",
          icon: anniver,
          noIcon: anniverGrey,
          check: false,
        },
        {
          index: 3,
          name: "赠送优惠卡券",
          icon: coupon,
          noIcon: couponGrey,
          check: false,
        },

        {
          index: 4,
          name: "首饰免费清洗",
          icon: clean,
          noIcon: cleanGrey,
          check: false,
        },
        {
          index: 5,
          name: "首饰免费鉴定",
          icon: identifications,
          noIcon: identificationsGrey,
          check: false,
        },
        {
          index: 6,
          name: "首饰原价赎回",
          icon: redemptions,
          noIcon: redemptionsGrey,
          check: false,
        },
        {
          index: 7,
          name: "贵重首饰免费保管",
          icon: custody,
          noIcon: custodyGrey,
          check: false,
        },
        {
          index: 8,
          name: "线上订单优先发货",
          icon: ship,
          noIcon: shipGrey,
          check: false,
        },
      ],
      pickerOptions: {
        disabledDate(time) {
          // 享受折扣选择有效期开始时间不能低于当前时间
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24);
          return time.getTime() < date;
        },
      },
    };
  },
  created() {
    const userInfo = storage.get("userInfo");
    this.userInfo = userInfo;
    this.token = storage.session.get("token") || Cookies.get("token");
    this.userLevelDetails();
    console.log(this.type);
    if (this.type === "edit") {
      this.getDetails();
      this.userLevelDetails();
    }
  },
  props: {
    share_type: Number,
    type: String,
    hierarchy_id: Number,
    client_level_id: Number,
  },
  methods: {
    /*
     *  根据id查询详情数据
     */
    getDetails() {
      userLevel({ client_level_id: this.client_level_id }).then((res) => {
        if (res.code === 1) {
          this.info = res.data.list[0];
          this.level_url = res.data.list[0].level_Img;
          this.levelImgList.forEach((item) => {
            if (item.icon === res.data.list[0].level_Img) {
              this.level_index = item.index;
            }
          });
          this.discount.forEach((item) => {
            res.data.list[0].privilege_ids.split(",").forEach((i) => {
              if (item.index === Number(i)) {
                item.check = true;
              }
            });
          });
        }
      });
    },
    /*
     *   获取品类下拉数据
     */
    getGoodsTypeList() {
      getListReq().then((res) => {
        if (res.code === 1) {
          this.attrList = this.handleArr(res.data);
        }
      });
    },
    handleArr(arr) {
      const newArr = [];
      function poling(arr, span) {
        arr.forEach((item) => {
          if (span || span === 0) {
            item.span = span + 1;
            item.goods_type_name = "├─" + item.goods_type_name;
          } else {
            item.span = 0;
          }
          newArr.push(item);
          if (item.children) {
            poling(item.children, item.span);
          }
        });
      }
      // 深复制
      const aArr = JSON.parse(JSON.stringify(arr));
      poling(aArr);
      return newArr;
    },
    /*
     *  获取会员特权数据
     */
    privList() {
      privList().then((res) => {
        if (res.code === 1) {
        }
      });
    },
    /*
     *  获取折扣详情
     */
    userLevelDetails() {
      userLevelDetails({ client_level_id: this.client_level_id }).then(
        (res) => {
          if (res.code === 1) {
            this.discountList = res.data.list;
          }
        }
      );
    },
    /*
     * 会员等级图片选择
     */
    getLevelImg() {
      this.visiable = true;
    },
    /*
     *  关闭会员图片弹窗
     */
    cancel() {
      this.visiable = false;
    },
    /*
     *  获取选择等级图片
     */
    handleLevel(item) {
      this.level_url = item.icon;
      this.level_index = item.index;
    },
    /*
     *  上传会员等级图片
     */
    handleImgSuccess(res, file) {
      this.level_url = res.data;
      this.level_img = URL.createObjectURL(file.raw);
    },
    /*
     *  会员等级图片上传限制
     */
    beforeAvatarUpload(file) {
      console.log(file);
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isPNG) {
        this.$message.error("上传等级图片只能是 PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isPNG && isLt2M;
    },
    /*
     *  关闭会员折扣弹窗
     */
    cancelDisCount() {
      this.discountVisiable = false;
      this.discountForm = {
        goods_type_id: [],
      };
      this.disIndex = null;
      this.action = null;
    },
    /*
     *  新增折扣
     */
    handleAddDisCount() {
      this.discountVisiable = true;
      this.getGoodsTypeList();
    },
    /*
     *  新增/编辑折扣信息
     */
    submitDisCount(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var data = this.discountForm;
          data.goods_type_id = data.goods_type_id.join(",");
          if (this.discountList.length === 0) {
            data.client_discount_id = 0;
            this.discountList.push(data);
          } else {
            let isEdit = false;
            this.discountList.forEach((item, index) => {
              if (this.disIndex === index) {
                isEdit = true;
              }
            });
            if (isEdit) {
              this.discountList[this.disIndex] = data;
            } else {
              data.client_discount_id = 0;
              this.discountList.push(data);
            }
          }
          this.cancelDisCount();
        }
      });
    },
    /*
     *  会员折扣操作
     */
    handleDetails(event, row, index) {
      console.log(row);
      this.disIndex = index;
      if (event === 1) {
        this.discountVisiable = true;
        this.discountType = "edit";
        if (row.goods_type_id instanceof Array === false) {
          row.goods_type_id = row.goods_type_id.split(",").map((item) => {
            return Number(item);
          });
        }

        this.discountForm = row;
        this.getGoodsTypeList();
      } else {
        this.$confirm("此操作将删除该分类, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            if (row.client_discount_id !== 0) {
              delDiscount({
                client_level_id: this.client_level_id,
                client_discount_id: row.client_discount_id,
              }).then((res) => {
                if (res.code === 1) {
                  this.action = null;
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                  this.userLevelDetails();
                }
              });
            } else {
              this.discountList.splice(this.discountList.indexOf(row), 1);
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    /*
     *  返回
     */
    goBack() {
      this.$emit("hide");
    },

    /*
     *  保存会员信息
     */
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // if (this.discountList.length === 0) {
          //   this.$message({
          //     type: "info",
          //     message: "请添加折扣数据",
          //   });
          //   return;
          // }
          this.loading = true;
          const data = {};
          // // 会员等级特权
          // const privilege_ids = [];
          // this.discount.forEach((item) => {
          //   if (item.check) {
          //     privilege_ids.push(item.index);
          //   }
          // });
          // data.privilege_ids = privilege_ids.join(",");
          data.level_Img = this.level_url;
          data.level_name = this.info.level_name;
          data.sales_amount = this.info.sales_amount;
          this.discountList.forEach((item) => {
            delete item.date;
          });
          data.discount = this.discountList;
          data.hierarchy_id = this.hierarchy_id;

          if (this.type === "add") {
            userLevelCreate(data)
              .then((res) => {
                console.log(res.code);
                if (res.code === 1) {
                  this.loading = false;
                  this.$message({
                    type: "success",
                    message: "添加成功!",
                  });
                  this.info = {};
                  this.level_url = "";
                  this.level_img = "";
                  this.discountList = [];
                  this.goBack();
                }
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            data.client_level_id = this.client_level_id;
            userLevelUpdate(data)
              .then((res) => {
                if (res.code === 1) {
                  this.$message({
                    type: "success",
                    message: "更新成功!",
                  });
                  this.info = {};
                  this.level_url = "";
                  this.level_img = "";
                  this.discountList = [];
                  this.goBack();
                }
              })
              .catch(() => {
                this.loading = false;
              });
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.info {
  padding-top: 20px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 6px;
}

.upload {
  width: 70px;
  height: 70px;
  border: 1px solid #c4cfdf;
  border-radius: 6px;
  .uploadLine {
    z-index: -1;
    margin: 34px auto;
    width: 26px;
    height: 2px;
    background: #c4cfdf;
    border-radius: 1px;
  }

  .uploadSu {
    margin: -47px 30px 0 34px;
    z-index: 55;
    width: 2px;
    height: 26px;
    background: #c4cfdf;
    border-radius: 1px;
  }
}
.upload:hover {
  cursor: pointer;
}

.imgBorder {
  width: auto;
  max-width: 100px;
  height: auto;
  max-height: 100px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  padding: 5px;
}

.imgBorder:hover {
  cursor: pointer;
}

.privilege {
  margin-left: 30px;
  display: flex;
  flex-wrap: wrap;

  .box {
    position: relative;
    margin-bottom: 35px;
    margin-right: 40px;
    width: 125px;
    height: 125px;
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 6px;
    text-align: center;
    font-size: 14px;
    color: #bcbcbc;

    .check {
      position: absolute;
      right: 8px;
      top: 5px;
    }

    .img {
      margin: 27px auto 0 auto;
      width: 38px;
      height: 38px;
    }
  }
  .select {
    border-color: #2791ff;
    background: rgba(39, 145, 255, 0.06);
    color: #333;
  }
}
.tableTop {
  height: 50px;
  background: linear-gradient(#f3f9ff 1%, #ffffff 100%);
  padding-top: 20px;
  border: 1px solid #ddd;
  border-radius: 6px 6px 0px 0px;
}

.levelList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 14px;
  .level {
    margin-right: 10px;
    width: 80px;
    height: 80px;
    background: url("../../../../assets/images/member/icon_unchecked.png");
    background-size: 100% 100%;

    .img {
      margin: 29px auto 0 12px;
    }
  }
  .selectLevel {
    background: url("../../../../assets/images/member/icon_checked.png") !important;
  }
  .level:hover {
    cursor: pointer;
  }
}
.avatar-uploader .el-upload {
  width: 100px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 290px;
  height: 170px;
  display: block;
}
.avatarOther {
  width: 100px;
  height: 100px;
  display: block;
}

.auditSuccess {
  padding-bottom: 20px;
  margin-top: 10px;
  height: 450px;
  background-color: white;

  .auditImg {
    margin: 10px auto 0 45%;
    width: 169px;
    height: 142px;
  }

  .audit_text {
    width: 100%;
    margin-bottom: 20px;
    font-size: 16px;
    text-align: center;

    span {
      font-size: 22px;
      color: #5493f5;
    }
  }
}
</style>